//@import "fonts";
//@import "components/chip.scss";
@import "~react-quill/dist/quill.snow.css";
@import "variables";

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 18px;
}

body {
    background: rgb(249, 249, 252);
}

.footer-container {
    background: #ffffff;
    padding: 20px 40px 0 40px;
    .footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #cccccc;
        padding: 10px 0 10px 10px;
    }
}

.footer-middle-content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.footer-navigation {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    li {
        position: relative;
        list-style-type: none;
        padding: 0 10px;
        a {
            color: #aaaaaa;
        }

        &:not(:last-child) {
            &:after {
                top: 0;
                right: 0;
                position: absolute;
                content: '|';
                color: #aaaaaa;
            }
        }

        &:last-child {
            padding: 0 0 0 10px;
        }
    }
}

.mention {
    background-color: transparent;
    color: #4caf50;
}

.ql-mention-list-container {
    display: inline-block;
    white-space: nowrap;
    width: auto;
}

.ql-mention-list-item {
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    font-size: 0.875rem;
    padding: 0 20px;
    vertical-align: middle;
}

//.MuiCardHeader-content {
//    &::after {
//        content: ' ';
//        font-size: 0;
//        line-height: 0;
//        display: block;
//        max-width: 200px;
//        border-bottom: 1px solid #2196f3;
//    }
//}

.ql-editor{
    font-size: 16px;
    min-height:200px;
}

.ql-tooltip {
    left: 0 !important;
}

.quill {
    &.required {
        .ql-container {
            &.ql-snow {
                border-bottom: 1px solid $colorError;
                border-left: 1px solid $colorError;
                border-right: 1px solid $colorError;
            }
        }
        .ql-toolbar {
            &.ql-snow {
                border-top: 1px solid $colorError;
                border-left: 1px solid $colorError;
                border-right: 1px solid $colorError;
            }
        }
    }
}

.sam-top-menu {
    //font-size: 18px;
    .MuiGrid-item {
        text-align: center;
        background-color: #99cc33;
        color: #FFFFFF;

        &:not(:last-child) {
            border-right: 1px solid #f9f9fc;
        }

        &.sam-top-link {

            &:hover {
                background-color: #99cc33bf;
            }

            a {
                display: block;
                padding: 20px;
                color: #fff;
                text-decoration: none;

                &.active {
                    cursor: default;
                    padding: 20px;
                    color: #000000de;
                    background-color: #ffffff;
                }
            }
        }
    }
}

//.sam-table {
//    tbody > tr {
//        cursor: pointer;
//        &:hover {
//            background-color: #f9f9fc;
//        }
//    }
//}

#sidebar-right {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 300px;
    z-index: 1300;
    height: 100%;
}